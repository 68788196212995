import { NotFoundScreen } from '_/screens/not-found-screen/not-found-screen';
import React from 'react';

import { LoginScreen } from '../screens/login-screen/login-screen';
import RecoverErrorPage from '../screens/login-screen/recover-error';
import RecoverPassword from '../screens/login-screen/recover-password';
import RecoverUsername from '../screens/login-screen/recover-username';
import SuccessRecover from '../screens/login-screen/success-recover';

export const publicRoutes = [
  {
    path: '/login',
    private: false,
    component: <LoginScreen />,
  },
  {
    path: '/recover-password',
    private: false,
    component: <RecoverPassword />,
  },
  {
    path: '/recover-username',
    private: false,
    component: <RecoverUsername />,
  },
  {
    path: '/recover-error',
    private: false,
    component: <RecoverErrorPage />,
  },
  {
    path: '/recover-success/:type',
    private: false,
    component: <SuccessRecover />,
  },
  {
    path: '*',
    private: false,
    component: <NotFoundScreen />,
  },
];
