import { ToastProvider } from '@openeducation/oe-it-components';
import { EnvironmentProvider, LoginProvider } from '@openeducation/oe-it-providers';
import {
  AuthenticationService,
  AxiosInterceptor,
  oeSsoCookieStorage,
  Environment,
  isTeacherProfile,
  PersonProfileService,
  teacherPortalAuthentication,
  getPersonIdFromToken,
} from '@openeducation/oe-it-services';
import { OeDefaultTheme, ThemeProvider } from '@openeducation/oe-it-themes';
import { Provider as AtomProvider } from 'jotai';
import React from 'react';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

interface AppProvidersProps {
  env: Environment;
  children?: React.ReactNode;
}

export const AppProviders: React.FC<AppProvidersProps> = ({ children, env }) => {
  React.useEffect(() => {
    if (env && oeSsoCookieStorage) {
      AxiosInterceptor(oeSsoCookieStorage, env);
    }
  }, [env]);

  async function validateAuthorization(id?: number | string) {
    if (!id) {
      return Promise.resolve(false);
    }
    const profile = await PersonProfileService.getProfileById(env, id as number);
    return isTeacherProfile(profile);
  }

  return (
    <AtomProvider>
      <EnvironmentProvider env={env}>
        <ThemeProvider theme={OeDefaultTheme}>
          <ToastProvider>
            <LoginProvider
              queryClient={queryClient}
              authenticateWithUsernameAndPassword={
                AuthenticationService.authenticateWithUsernameAndPassword
              }
              resetUserPassword={AuthenticationService.resetUserPassword}
              getIdFromToken={getPersonIdFromToken}
              validateAuthentication={teacherPortalAuthentication}
              validateAuthorization={validateAuthorization}
              authDataStorage={oeSsoCookieStorage}
            >
              {children}
            </LoginProvider>
          </ToastProvider>
        </ThemeProvider>
      </EnvironmentProvider>
    </AtomProvider>
  );
};
