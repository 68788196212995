import { RecoverSuccess } from '@openeducation/oe-it-components';
import React from 'react';
import { useNavigate, useParams } from 'react-router-native';

export function SuccessRecover() {
  const { type } = useParams() as { type: 'password' | 'username' };
  const navigate = useNavigate();

  return (
    <RecoverSuccess
      backAction={() => navigate(-1)}
      actionLink={() => navigate('/login')}
      type={type}
    />
  );
}

export default SuccessRecover;
