import { CourseType } from '@openeducation/oe-it-shared-types';

export type UseClassroomErrorMessageProps = {
  classroomId: number;
  classroomUrl: string;
  classType: CourseType;
};

export const useClassroomErrorMessage = ({
  classroomId,
  classroomUrl,
  classType,
}: UseClassroomErrorMessageProps) => {
  const message = (() => {
    const isPrivateClass = classType === CourseType.PRIVATE_CLASS;
    if (!classroomUrl) {
      return 'There is an error with your request: classroomUrl is a required parameter';
    }
    if (!classroomId) {
      return 'There is an error with your request: classroomId is a required parameter';
    }
    if (isPrivateClass) {
      return 'Your Private Class has opened in another browser tab. Happy Teaching!';
    }
    return 'Your class has opened in another browser tab. Happy Teaching!';
  })();

  return {
    message,
  };
};
