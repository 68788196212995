import { RecoverUsernamePage } from '@openeducation/oe-it-components';
import * as React from 'react';
import { useNavigate } from 'react-router-native';

export const RecoverUsername = () => {
  const navigate = useNavigate();
  return (
    <RecoverUsernamePage
      backAction={() => navigate('/login')}
      onError={() => navigate('/recover-error')}
      onSuccess={() => navigate('/recover-success/username')}
    />
  );
};
export default RecoverUsername;
