import { CenteredLoading, ErrorMessage, mt } from '@openeducation/oe-it-components';
import { teacherProfileAtom } from '@openeducation/oe-it-jotai-atoms';
import { getUrlParams } from '@openeducation/oe-it-services';
import { StudentPendingFeedback } from '@openeducation/oe-it-teacher-portal';
import { useAtomValue } from 'jotai';
import * as React from 'react';

export const StudentPendingFeedbackScreen = () => {
  const { studentId, classroomId, teacherId } = getUrlParams();
  const teacherProfile = useAtomValue(teacherProfileAtom);

  if (!studentId) {
    return (
      <ErrorMessage message="There is an error with your request: studentId is a required parameter" />
    );
  }
  if (!classroomId) {
    return (
      <ErrorMessage message="There is an error with your request: classroomId is a required parameter" />
    );
  }
  if (!teacherId) {
    return (
      <ErrorMessage message="There is an error with your request: teacherId is a required parameter" />
    );
  }

  if (!teacherProfile) {
    return <CenteredLoading size={100} style={mt(100)} />;
  }

  return (
    <StudentPendingFeedback classroomId={classroomId} studentId={studentId} teacherId={teacherId} />
  );
};
