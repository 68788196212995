import { RecoverError } from '@openeducation/oe-it-components';
import * as React from 'react';
import { useNavigate } from 'react-router-native';

export const RecoverErrorPage = () => {
  const navigate = useNavigate();
  return <RecoverError backAction={() => navigate('/login')} actionLink={() => navigate(-1)} />;
};

export default RecoverErrorPage;
