import { Loading } from '@openeducation/oe-it-components';
import { useLoginContext, PortalNavigationProvider } from '@openeducation/oe-it-providers';
import * as React from 'react';

import { privateRoutes } from './private-routes';
import { publicRoutes } from './public-routes';
import { Navigate, Route, Routes, useLocation, useNavigate } from './router';

export const generalRoutes = [...privateRoutes, ...publicRoutes];

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoginSuccess } = useLoginContext();
  const location = useLocation();

  if (!isLoginSuccess) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export const AppRoutes = () => {
  const { isInitialized } = useLoginContext();
  const navigate = useNavigate();

  if (!isInitialized) {
    return <Loading size="large" />;
  }

  return (
    <PortalNavigationProvider navigate={navigate}>
      <Routes>
        {generalRoutes?.map((route) =>
          route.private ? (
            <Route
              key={route.path}
              path={route.path}
              element={<RequireAuth>{route.component}</RequireAuth>}
            />
          ) : (
            <Route key={route.path} path={route.path} element={route.component} />
          ),
        )}
      </Routes>
    </PortalNavigationProvider>
  );
};
