import { LoginPage } from '@openeducation/oe-it-components';
import { useLoginContext } from '@openeducation/oe-it-providers';
import React, { useEffect } from 'react';

import useLocationPath from '../../hooks/use-location-path';
import { useNavigate } from '../../router/router';

export const LoginScreen = () => {
  const navigate = useNavigate();
  const { getFromPath } = useLocationPath();
  const { setOnLoginSuccess } = useLoginContext();

  useEffect(() => {
    const from = getFromPath();
    setOnLoginSuccess({ params: [from, { replace: true }], callback: navigate });
  }, [getFromPath, navigate, setOnLoginSuccess]);

  return (
    <LoginPage
      onRecoverPassword={() => navigate('/recover-password')}
      onRecoverUsername={() => navigate('/recover-username')}
    />
  );
};
