import { AccountScreen } from '_/screens/account-screen/account-screen';
import { ClassroomScreen } from '_/screens/classroom-screen/classroom-screen';
import { MessagesScreen } from '_/screens/messages-screen/messages-screen';
import React from 'react';

import { Navigate } from './router';
import { DashboardScreen } from '../screens/dashboard-screen/dashboard-screen';
import { PendingFeedbacksScreen } from '../screens/pending-feedbacks-screen/pending-feedbacks-screen';
import { StudentPendingFeedbackScreen } from '../screens/student-pending-feedback-screen/student-pending-feedback-screen';

export const privateRoutes = [
  {
    path: '/',
    private: true,
    component: <Navigate replace to="/pending-feedbacks" />,
  },
  {
    path: '/dashboard',
    private: true,
    component: <DashboardScreen />,
  },
  {
    path: '/account',
    private: true,
    component: <AccountScreen />,
  },
  {
    path: '/messages',
    private: true,
    component: <MessagesScreen />,
  },
  {
    path: '/pending-feedbacks',
    private: true,
    component: <PendingFeedbacksScreen />,
  },
  {
    path: '/student-pending-feedback',
    private: true,
    component: <StudentPendingFeedbackScreen />,
  },
  {
    path: '/classroom',
    private: true,
    component: <ClassroomScreen />,
  },
];
