import { RecoverPasswordPage } from '@openeducation/oe-it-components';
import { useRecoverPassword } from '@openeducation/oe-it-providers';
import * as React from 'react';
import { useNavigate } from 'react-router-native';

export const RecoverPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useRecoverPassword({
    onError: () => navigate('/recover-error'),
    onSuccess: () => navigate('/recover-success/password'),
  });
  return <RecoverPasswordPage backAction={() => navigate('/login')} onSubmit={forgotPassword} />;
};

export default RecoverPassword;
