import { CenteredLoading, mt } from '@openeducation/oe-it-components';
import {
  teacherProfileAtom,
  selectedLiveClassFeedbackClassroomIdAtom,
  selectedLiveClassFeedbackStudentIdAtom,
} from '@openeducation/oe-it-jotai-atoms';
import { usePendingFeedbacks } from '@openeducation/oe-it-providers';
import { getUrlParams } from '@openeducation/oe-it-services';
import { PendingFeedbacks } from '@openeducation/oe-it-teacher-portal';
import { useAtomValue } from 'jotai';
import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from '../../router/router';

export const PendingFeedbacksScreen = () => {
  const navigate = useNavigate();
  const params = getUrlParams();
  const teacherProfile = useAtomValue(teacherProfileAtom);
  const { pendingFeedbacks, isLoadingPendingFeedbacks, reFetchPendingFeedbacks } =
    usePendingFeedbacks({
      teacherProfile,
    });

  const studentId = useAtomValue(selectedLiveClassFeedbackStudentIdAtom);
  const classroomId = useAtomValue(selectedLiveClassFeedbackClassroomIdAtom);

  const navigateToPendingFeedback = React.useCallback(
    (studentId?: number, classroomId?: number) => {
      navigate({
        pathname: '/pending-feedbacks',
        search: `?studentId=${studentId}&classroomId=${classroomId}`,
      });
    },
    [navigate],
  );

  useEffect(() => {
    // Only navigate when there is no selected feedback in the url
    if (params && params.noFeedback) {
      return;
    }
    // Only navigate when there is no selected feedback in the url
    if (params?.studentId && params?.classroomId) {
      return;
    }

    // Navigate when studentId and classroomId atoms change
    if (studentId && classroomId) {
      navigateToPendingFeedback(studentId, classroomId);
    }
  }, [
    studentId,
    classroomId,
    navigateToPendingFeedback,
    params?.classroomId,
    params?.studentId,
    params?.noFeedback,
    params,
  ]);

  const handleFeedbackSubmit = () => {
    navigate({
      pathname: '/pending-feedbacks',
      search: `?noFeedback=1`,
    });
    reFetchPendingFeedbacks();
  };

  if (!teacherProfile || isLoadingPendingFeedbacks) {
    return <CenteredLoading size={100} style={mt(100)} />;
  }

  return (
    <PendingFeedbacks
      onSelectedFeedbackChange={navigateToPendingFeedback}
      pendingFeedbacks={pendingFeedbacks}
      onFeedbackSubmitted={handleFeedbackSubmit}
      goToNextFeedback={() =>
        navigate({
          pathname: '/pending-feedbacks',
        })
      }
    />
  );
};
