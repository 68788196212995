import { useCallback } from 'react';

import { useLocation } from '../router/router';

type LocationState = {
  from: {
    path: string;
  };
};

const useLocationPath = () => {
  const location = useLocation();

  const getFromPath = useCallback(
    () => (location.state as LocationState)?.from?.path || '/',
    [location.state],
  );

  return {
    getFromPath,
  };
};

export default useLocationPath;
