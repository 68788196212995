import { CenteredLoading, mt } from '@openeducation/oe-it-components';
import { teacherProfileAtom } from '@openeducation/oe-it-jotai-atoms';
import { DashboardPage } from '@openeducation/oe-it-teacher-portal';
import { useAtomValue } from 'jotai';
import * as React from 'react';

export const DashboardScreen = () => {
  const teacherProfile = useAtomValue(teacherProfileAtom);

  if (!teacherProfile) {
    return <CenteredLoading size={100} style={mt(100)} />;
  }

  return <DashboardPage teacherProfile={teacherProfile} />;
};
